<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Barang Keluar</p>
     <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datakeluar.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
          <tr>        
             <td class="capitalize">tanggal</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="datakeluar.tanggal" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">barcode/barang</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <v-select required :options="produks" label="item_data" v-model="datakeluar.id_produk" @input="cekstok" :reduce="e => e.id"></v-select>
                <!-- <select class='form-control' v-model="datakeluar.id_produk" @change="cekstok">
                <option v-for="(item, index) in produks" :key="index+'pridils'" :value="item.id">{{item.barcode}}/{{item.nama_produk}}</option>
                </select> -->
             </td>
         </tr>
          <tr>        
             <td class="capitalize">harga</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="number" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="datakeluar.harga" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">Jumlah keluar</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input type="number" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="datakeluar.jumlah" >
                 </div>
                 <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">keterangan</td>
             <td class="pl-2 pr-2">:</td>
             <td>
              <div class="sm-form">
              <textarea
              type="text"
              id="keterangan"
              name="keterangan"
              rows="2"
              placeholder="keterangan..."
              class="form-control md-textarea"
              v-model="datakeluar.keterangan"
              ></textarea>
              </div>
             </td>
         </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datakeluar = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class=" col-sm-7 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length>0" class="p-2">
               <div class="text-right">
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
                <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','harga','stok','keluar']" @selected="ambil" ref="btable" />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
import firebase from 'firebase'
let rdb = firebase.database();
let sdb = new autophp();
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datakeluar: {},
      produks:[],
      btn: "tambah",
      stok:0,
      datanya:[],
    };
  },
  methods: {
    format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
     async cekstok(item){
        console.log(this.datakeluar)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.datakeluar.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.datakeluar.diskon=res[0].diskon
         }
          this.datakeluar.harga=res[0].harga
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.datakeluar.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.datakeluar.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.datakeluar.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.$forceUpdate();
    },
    getData(){
      sdb.collection("app_kasir_stok_keluar",false).doc().select(`select tb1.id,tb1.tanggal,tb2.barcode,tb1.keterangan,tb1.jumlah,tb1.status,tb2.nama_produk,tb1.id_produk from app_kasir_stok_keluar tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      sdb.collection("app_kasir_produk").doc().get("*","order by id").then(res => {
          this.produks=res;
            this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
      
    },
    ambil(data) {
      this.datakeluar = data;
      this.cekstok();
      this.btn = "update";
      this.$forceUpdate();
    },
   async submit() {
      let that=this
      this.datakeluar.status='keluar'
      this.datakeluar.penginput=this.$store.state.users.id
      this.datakeluar.tanggal_w=this.format(new Date(this.datakeluar.tanggal));
      this.datakeluar.cabang=this.$store.state.data.toko.cabang
      if(this.datakeluar.id_produk==''){
        alert('Barcode Produk terlebih dahulu')
          return
      }
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datakeluar
          delete this.datakeluar.id;
          await sdb
            .collection("app_kasir_stok_keluar")
            .doc()
            .set(this.datakeluar)
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')

        } else if (this.btn == "update") {
         await sdb
            .collection("app_kasir_stok_keluar")
            .doc()
            .set(this.datakeluar)
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
         await sdb
            .collection("app_kasir_stok_keluar")
            .doc(this.datakeluar.id)
            .delete()
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    let that=this;
    this.getData();
     rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
          if(that.produks.length>0){
            console.log('produks',that.produks)
            let data =that.produks.filter(k=>{
              console.log(k.barcode)
              if(k.barcode==e.val().id){
                return k
              }
            })
            console.log(data)
            that.datakeluar.id_produk=data[0].id
            that.cekstok();
            that.$forceUpdate();
          }
    }) 
    this.datakeluar.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")

  },
};
</script>